<template>
  <b-card title="Take a photo" align="center">
    <b-card-text>
      <iframe
        width="300"
        height="167"
        src="https://www.youtube.com/embed/DaJ3kVK40dw"
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-card-text>
    <b-card-text class="text-left">
      <ul class="list-unstyled">
        <b-media tag="li">
          <b-img
            id="footprynt-step-1"
            slot="aside"
            src="https://widget.footprynt.io/images/paper_100.jpg"
            width="100"
            alt="step 1"
          ></b-img>

          <p class="mb-0">
            Place a piece of plain white
            <strong id="letter-paper">Letter</strong> or
            <strong id="a4-paper">A4</strong> paper on a
            <strong>hard, dark colored floor</strong>.
          </p>
          <b-popover
            target="letter-paper"
            placement="bottomright"
            triggers="hover focus"
            content="8.5x11in"
          >
          </b-popover>
          <b-popover
            target="a4-paper"
            placement="bottomright"
            triggers="hover focus"
            content="21x29.7cm"
          >
          </b-popover>
        </b-media>

        <b-media tag="li" class="my-4">
          <b-img
            slot="aside"
            src="https://widget.footprynt.io/images/sock_100.jpeg"
            width="100"
            alt="step 2"
          ></b-img>

          <p class="mb-0">
            Line up your <strong>heel</strong> with the
            <strong>back edge</strong> of the paper.
          </p>
        </b-media>

        <b-media tag="li">
          <b-img
            slot="aside"
            src="https://widget.footprynt.io/images/sock2_100.jpg"
            width="100"
            alt="step 3"
          ></b-img>

          <p class="mb-0">
            With <strong>all four corners of the paper</strong> visible, take a
            picture!
          </p>
        </b-media>
      </ul>
    </b-card-text>
    <b-card-text>
      <b-button
        class="mr-1"
        variant="dark"
        v-on:click="$router.replace({ name: 'Introduction' })"
        >&lt;</b-button
      >
      <b-button variant="dark" v-on:click="$router.replace({ name: 'StepTwo' })"
        >Next</b-button
      >
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "StepOne",
};
</script>

<style scoped></style>

<template>
  <b-media tag="li" class="my-2">
    <template v-slot:aside>
      <b-button v-b-modal="`modal-${image.id}`" variant="link" class="p-0">
        <b-img class="foot-thumb" :id="image.id" rounded :src="image.thumbnail" alt="foot"></b-img>
      </b-button>
      <HowDoesItLook :image="image" :phone-number="phoneNumber"></HowDoesItLook>
    </template>
    <div>
      <span v-if="imageRef == null || imageRef.isGood == null">Click on image to confirm</span>
      <span v-else>
        <Size :image="image" :is-good="imageRef.isGood"></Size>
      </span>
      <span>
        <b-button
            v-if="user && user.phoneNumber === phoneNumber.number"
            v-on:click="deleteFromProfile(image)"
            size="sm"
            variant="light"
            style="float: right">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12pt"
               height="12pt" viewBox="0 0 12 12" version="1.1">
            <g id="surface1">
              <path
                  style="fill:none;stroke-width:2.08;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;"
                  d="M 8.996633 4.425417 L 8.996633 3.001737 C 8.996633 1.578056 9.62271 1.003438 11.003508 1.003438 L 15.000106 1.003438 C 16.389481 1.003438 16.998405 1.578056 16.998405 3.001737 L 16.998405 4.425417 "
                  transform="matrix(0.455466,0,0,0.455466,0,0)"/>
              <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
                    d="M 10.476562 1.824219 C 10.476562 1.570312 10.273438 1.367188 10.019531 1.367188 L 1.824219 1.367188 C 1.570312 1.367188 1.367188 1.570312 1.367188 1.824219 L 0.910156 1.824219 L 0.910156 2.734375 L 10.933594 2.734375 L 10.933594 1.824219 Z M 1.824219 3.1875 L 1.824219 10.476562 C 1.824219 11.226562 2.4375 11.84375 3.1875 11.84375 L 8.65625 11.84375 C 9.40625 11.84375 10.019531 11.226562 10.019531 10.476562 L 10.019531 3.1875 Z M 4.554688 10.019531 L 3.640625 10.019531 L 3.640625 4.554688 L 4.554688 4.554688 Z M 6.378906 10.019531 L 5.464844 10.019531 L 5.464844 4.554688 L 6.378906 4.554688 Z M 8.199219 10.019531 L 7.289062 10.019531 L 7.289062 4.554688 L 8.199219 4.554688 Z M 8.199219 10.019531 "/>
            </g>
          </svg>
        </b-button>
      </span>
    </div>
  </b-media>
</template>

<script>
  import {mapState} from 'vuex'
  import {db} from '../firebase';
  import HowDoesItLook from "./HowDoesItLook";
  import Size from "./Size";

  export default {
    name: "PhoneNumberImage",
    props: {
      image: Object,
      phoneNumber: Object
    },
    data() {
      return {
        imageRef: null
      }
    },
    components: {Size, HowDoesItLook},
    computed: {
      ...mapState(['user', 'sizeMapping', 'gender'])
    },
    firestore() {
      return {
        imageRef: db.collection("users").doc(this.user.uid).collection('phone_numbers').doc(this.phoneNumber.number).collection("images").doc(this.image.id)
      }
    },
    methods: {
      calculateSize(foot_size) {
        let mapping = this.sizeMapping.mapping;
        if (this.gender) {
          mapping = mapping[this.gender]
        }
        let result = mapping.find((o) => {
          if (foot_size.foot_length >= o.from && foot_size.foot_length < o.to) {
            return true;
          }
        })
        if (result) {
          return result.size;
        } else {
          return -1;
        }
      },
      deleteFromProfile(image) {
        this.$ga.event({
          eventCategory: 'Image',
          eventAction: 'deletePhoneNumberImage',
          eventLabel: window.location.hostname
        })
        db.collection("phone_numbers").doc(this.phoneNumber.number).collection("images").doc(image.id).delete()
          .then(res => {
            console.log("successfully deleted image");
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      resolvePopoverContent(image) {
        let size = this.calculateSize(image);
        let foot_width = image.foot_width ? "width of " + image.foot_width.toFixed(2) + " cm and " : "";
        let paper_size = image.paper_size ? image.paper_size : "Letter";
        if (size > -1) {
          return "Recommended size based on measured foot length of " + image.foot_length.toFixed(2) + " cm and " + foot_width + paper_size + " paper size"
        } else {
          return "This retailer doesn't carry the shoe in your size. Size based on measured foot length of " + image.foot_length.toFixed(2) + " cm and " + paper_size + " paper size"
        }
      }
    }
  }
</script>

<style scoped>
  .foot-thumb {
    object-fit: cover;
    width: 50px;
    height: 50px;
  }
</style>
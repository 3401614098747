import Vue from "vue"
import BootstrapVue from 'bootstrap-vue'
import VueTelInput from "vue-tel-input";
import VueAnalytics from 'vue-analytics'
import {
  AlertPlugin,
  ButtonPlugin,
  CardPlugin,
  CarouselPlugin,
  FormPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LinkPlugin,
  ListGroupPlugin,
  MediaPlugin,
  ModalPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TooltipPlugin } from 'bootstrap-vue'
import {firestorePlugin} from 'vuefire'
import App from "./App.vue"
import Footprynt from "./Footprynt.vue"
import FootpryntText from "./FootpryntText.vue"
import router from "./router"
import store from "./store"
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import ImageUploader from 'vue-image-upload-resize'

import vueCustomElement from "vue-custom-element"
import "vue-tel-input/dist/vue-tel-input.css";
import "document-register-element/build/document-register-element"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.component("vue-tel-input", VueTelInput);
Vue.use(vueCustomElement)
Vue.use(firestorePlugin);
Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LinkPlugin)
Vue.use(ListGroupPlugin)
Vue.use(MediaPlugin)
Vue.use(ModalPlugin)
Vue.use(PopoverPlugin)
Vue.use(ProgressPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TooltipPlugin)
Vue.use(BootstrapVue)
Vue.use(ImageUploader);

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueAnalytics, {
  id: 'UA-143594987-1',
  router,
  autoTracking: {
    exception: true,
    exceptionLogs: true
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

Vue.mixin({
  methods: {
    resolvePhoneNumber(number) {
      const phoneNumber = parsePhoneNumberFromString(number, 'US')
      if (phoneNumber) {
        if (phoneNumber.isValid()) {
          return phoneNumber;
        }
      }
      return null
    }
  }
})

Footprynt.store = store
Footprynt.router = router
FootpryntText.store = store
Vue.customElement("footprynt-io", Footprynt)
Vue.customElement("footprynt-text", FootpryntText)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="processedImages">
    <div class="mt-1 justify-content-between d-flex">
      <span class="phoneNumber">{{formatNumber(phoneNumber.number)}}</span>
      <span class="mr-1">
        <b-button
            v-if="user"
            v-on:click="removeFromProfile(phoneNumber)"
            variant="light"
            class="p-0 line-height-0"
            >
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12pt" height="12pt" viewBox="0 0 12 12" version="1.1">
            <g id="surface1">
              <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 10.765625 1.765625 L 10.234375 1.234375 L 6 5.472656 L 1.765625 1.234375 L 1.234375 1.765625 L 5.472656 6 L 1.234375 10.234375 L 1.765625 10.765625 L 6 6.527344 L 10.234375 10.765625 L 10.765625 10.234375 L 6.527344 6 Z M 10.765625 1.765625 "/>
            </g>
          </svg>
        </b-button>
      </span>
    </div>
        <p :class="`foot-${idx}`" v-text="name" contenteditable="true" @blur="onEdit" @keydown.enter="endEdit"></p>
    <div v-if="images.length === 0">
      No processed images yet
    </div>
    <div v-else>
      <ul class="list-unstyled">
        <span v-for="(image, idx) in images" :key="idx">
          <PhoneNumberImage :image="image" :phone-number="phoneNumber"></PhoneNumberImage>
        </span>
      </ul>
    </div>
  </div>
</template>

<script>
  import {parsePhoneNumberFromString} from 'libphonenumber-js'
  import {db} from '../firebase';
  import {mapState} from 'vuex'
  import PhoneNumberImage from "./PhoneNumberImage";

  export default {
    name: 'PhoneNumberImages',
    props: {
      phoneNumber: Object,
      idx: Number
    },
    components: {PhoneNumberImage},
    data() {
      return {
        name: this.phoneNumber.name ? this.phoneNumber.name : 'Phone Number ' + (this.idx + 1),
        images: []
      }
    },
    computed: {
      ...mapState(['user', 'bestMeasurement'])
    },
    methods: {
      formatNumber(number) {
        return parsePhoneNumberFromString(number, 'US').formatInternational()
      },
      onEdit(evt) {
        this.$ga.event({
          eventCategory: 'Widget',
          eventAction: 'editPhoneNumberName',
          eventLabel: window.location.hostname
        })
        var text = evt.target.innerText
        this.name = text
        db.collection("users").doc(this.user.uid).collection("phone_numbers").doc(this.phoneNumber.number)
          .update({name:text})
          .then(value => console.log("name updated")) //maybe put a spinner here
          .catch(reason => console.log(reason))
      },
      endEdit(){
        this.$el.querySelector('.foot-'+this.idx).blur()
      },
      removeFromProfile(phoneNumber) {
        this.$ga.event({
          eventCategory: 'Widget',
          eventAction: 'removePhoneNumber',
          eventLabel: window.location.hostname
        })
        db.collection("users").doc(this.user.uid).collection('phone_numbers').doc(phoneNumber.number).delete()
          .then(res => {
            this.$store.dispatch("deleteSavedPhoneNumber", phoneNumber)
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    firestore() {
      return {
        images: db.collection('phone_numbers').doc(this.phoneNumber.number).collection('images')
      }
    },
    watch: {
      phoneNumber: {
        immediate: true,
        handler(phoneNumber) {
          this.$bind('images', db.collection('phone_numbers').doc(phoneNumber.number).collection('images'))
            .catch(reason => console.log(reason))
        }
      },
      images: {
        handler(images) {
          if (this.user && this.user.phoneNumber === this.phoneNumber.number) {
            this.$store.dispatch("updateBestMeasurement", null)
            for (var i=0; i<images.length; i++) {
              if (this.bestMeasurement == null || this.bestMeasurement.sum_of_all_errors > images[i].sum_of_all_errors) {
                this.$store.dispatch("updateBestMeasurement", images[i])
              }
            }
          }
        }
      }
    }
  };

</script>

<style scoped>
  .phoneNumber {
    padding-right: 10px;
    font-size: 1.00rem;
  }
  .line-height-0 {
    line-height: 0;
  }
</style>
<template>
  <b-card-body>
    <b-card-text class="text-center">
      <div id="firebaseui-auth-container"></div>
      <b-button variant="link"
                v-on:click="back()">Back</b-button>
      <div id="loader">Loading...</div>
    </b-card-text>
  </b-card-body>
</template>

<script>
  import {firebase, auth} from "../firebase"
  const firebaseui = require('firebaseui');
  import "../../node_modules/firebaseui/dist/firebaseui.css"

  export default {
    props: {
      backTo: String
    },
    mounted() {
      let self = this

      let uiConfig = {
        autoUpgradeAnonymousUsers: true,
        signInOptions: [
          // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
          }
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {

            self.$ga.event({
              eventCategory: 'Auth',
              eventAction: 'userLoggedIn_' + authResult.additionalUserInfo.providerId,
              eventLabel: window.location.hostname
            })
            self.$store.dispatch("updateUser", authResult.user)
            self.$router.replace({name:'Recommendation'})
          },
          signInFailure: function(error) {
            // For merge conflicts, the error.code will be
            // 'firebaseui/anonymous-upgrade-merge-conflict'.
            if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
              return Promise.resolve();
            }
            // The credential the user tried to sign in with.
            var cred = error.credential;
            // Copy data from anonymous user to permanent user and delete anonymous
            // user.
            // ...
            // Finish sign-in after data is copied.
            firebase.auth().signInWithCredential(cred).then(value => {
              self.$store.dispatch("updateUser", value)
              self.$router.replace({name:'Recommendation'})
            });
          },
          uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none';
          }
        },
        // Terms of service url.
        tosUrl: 'https://footprynt.io/terms',
        // Privacy policy url.
        privacyPolicyUrl: 'https://footprynt.io/privacy_policy'
      }
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(auth);
      }
      ui.start("#firebaseui-auth-container", uiConfig)
    },
    methods: {
      back() {
        this.$router.replace({name: this.backTo})
      }
    }
  }
</script>
<template>
  <b-card
      title="Let's find your size"
      align="center"
  >
    <b-card-text>
      All you need is your camera phone and a plain piece of white paper (Letter or A4).
    </b-card-text>
    <b-card-text>
      <b-button variant="dark" v-on:click="$router.replace({name:'StepOne'})">Get Started</b-button>
    </b-card-text>
    <b-card-text>
      <b-button variant="link"
                v-if="!user || user.isAnonymous"
                v-on:click="$router.replace({name:'Login', params: {backTo:'Introduction'}})">Do you already have a profile? <p>Login here</p></b-button>
<!--      <b-link v-else v-on:click="signOut">Not {{user.name ? user.name : user.email ? user.email : user.phoneNumber ? this.resolvePhoneNumber(user.phoneNumber).formatInternational() : 'you'}}?</b-link>-->
    </b-card-text>
  </b-card>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "Introduction",
    computed: {
      ...mapState(['user'])
    }
  }
</script>

<style scoped>

</style>
<template>
  <b-card>
    <b-media no-body>
      <b-media-aside vertical-align="center">
        <b-img
          v-if="!imgsrc"
          class="shoe-image"
          src="https://widget.footprynt.io/images/running-shoe-placeholder.png"
          alt="placeholder"
        ></b-img>
        <b-img v-else class="shoe-image" :src="imgsrc" alt="shoe"></b-img>
      </b-media-aside>
      <b-media-body class="ml-3">
        <b-card-body class="pt-0 pr-0">
          <b-card-title title-tag="h6">{{ title }}</b-card-title>
          <b-card-sub-title class="mb-2" sub-title-tag="p">{{
            subtitle
          }}</b-card-sub-title>
          <b-card-text>
            <p>Recommended Size:</p>

            <b-button
              :id="`popover-recommendation`"
              class="font-weight-bolder larger-font"
              size="lg"
              variant="light"
            >
              {{
                bestMeasurement != null
                  ? calculateSize(bestMeasurement) > -1
                    ? calculateSize(bestMeasurement)
                    : "N/A"
                  : "-"
              }}
            </b-button>
            <!-- <b-button>{{ resolveBestMeasurement(bestMeasurement) }}</b-button> -->
            <!-- <b-popover
              v-if="bestMeasurement"
              :target="`popover-recommendation`"
              placement="bottom"
              triggers="hover focus"
              :content="`${resolvePopoverContent(bestMeasurement)}`"
            >
            </b-popover> -->
            <b-popover
              v-if="bestMeasurement"
              :target="`popover-recommendation`"
              placement="bottom"
              triggers="hover focus"
              :content="`${resolveBestMeasurement(bestMeasurement)}`"
            ></b-popover>
          </b-card-text>
        </b-card-body>
      </b-media-body>
    </b-media>
    <b-card-text align="center">
      <!-- <b-button variant="dark" v-on:click="continueShopping()"
        >Continue Shopping</b-button
      > -->
      <b-button
        variant="outline-success"
        href="https://www.tryfootprynt.com/shop"
        >Continue Shopping</b-button
      >
    </b-card-text>
    <b-card-text align="center">
      <b-link v-if="user && !user.isAnonymous" v-on:click="signOut"
        >Not
        {{
          user.name
            ? user.name
            : user.email
            ? user.email
            : user.phoneNumber
            ? this.resolvePhoneNumber(user.phoneNumber).formatInternational()
            : "you"
        }}?
      </b-link>
      <b-link v-else v-on:click="startOver">Start Over</b-link>
    </b-card-text>
    <b-card-body class="py-0">
      <div class="separator"></div>
    </b-card-body>
    <b-card-text class="my-1 text-center">
      <b-link v-on:click="$router.replace({ name: 'StepOne' })"
        >How to take a Footprynt photo</b-link
      >
    </b-card-text>
    <ImageManagerSlim></ImageManagerSlim>
    <b-card-text align="center" class="mt-4">
      <div v-if="!user || user.isAnonymous">
        <b-button
          variant="dark"
          v-on:click="
            $router.replace({
              name: 'Login',
              params: { backTo: 'Recommendation' },
            })
          "
          >Save Profile to Footprynt</b-button
        >

        <b-card-body
          >Use your profile for future sizings on sites that use Footprynt
        </b-card-body>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../firebase";
import ImageManagerSlim from "../components/ImageManagerSlim";

export default {
  name: "Recommendation",
  components: { ImageManagerSlim },
  computed: {
    imageCount() {
      return (
        this.$store.state.uploads.length + this.$store.state.phoneNumbers.length
      );
    },
    ...mapState([
      "user",
      "phoneNumbers",
      "sizeMapping",
      "gender",
      "bestMeasurement",
      "title",
      "subtitle",
      "imgsrc",
    ]),
  },
  methods: {
    calculateSize(foot_size) {
      let mapping = this.sizeMapping.mapping;
      if (this.gender) {
        mapping = mapping[this.gender];
      }
      let result = mapping.find((o) => {
        if (foot_size.foot_length >= o.from && foot_size.foot_length < o.to) {
          return true;
        }
      });
      if (result) {
        return result.size;
      } else {
        return -1;
      }
    },
    resolvePopoverContent(image) {
      let size = this.calculateSize(image);

      let foot_width = image.foot_width
        ? "width of " + image.foot_width.toFixed(2) + " cm and "
        : "";
      let paper_size = image.paper_size ? image.paper_size : "Letter";
      if (size > -1) {
        return (
          "Recommended size based on measured foot length of " +
          image.foot_length.toFixed(2) +
          " cm and " +
          foot_width +
          paper_size +
          " paper size"
        );
      } else {
        return (
          "This retailer doesn't carry the shoe in your size. Size based on measured foot length of " +
          image.foot_length.toFixed(2) +
          " cm using " +
          paper_size +
          " paper"
        );
      }
    },

    resolveBestMeasurement(image) {
      let size = this.calculateSize(image);
      let mapped = this.sizeMapping.mapping;
      let foot = image.foot_length.toFixed(2);

      let sizeTo = mapped.find((bestMeasurement) => {
        if (foot >= bestMeasurement.from && foot <= bestMeasurement.to) {
          return bestMeasurement;
        }
      });
      if (sizeTo && size > -1) {
        return (
          "Your recommended size is  " +
          size +
          " based on a length of " +
          foot +
          "cm." +
          " A size " +
          size +
          " is from " +
          sizeTo.from +
          "cm " +
          " to " +
          sizeTo.to +
          "cm. "
        );
      } else {
        return (
          "This retailer doesn't carry the shoe in your size. Size based on measured foot length of " +
          image.foot_length.toFixed(2) +
          "cm"
        );
      }
    },

    signOut() {
      auth
        .signOut()
        .then((value) => {
          this.$ga.event({
            eventCategory: "Auth",
            eventAction: "userSignedOut",
            eventLabel: window.location.hostname,
          });
          this.$router.replace({ name: "Introduction" });
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    startOver() {
      this.$ga.event({
        eventCategory: "Navigation",
        eventAction: "startOver",
        eventLabel: window.location.hostname,
      });
      this.$router.replace({ name: "Introduction" });
    },
    // continueShopping() {
    //   this.$ga.event({
    //     eventCategory: "Widget",
    //     eventAction: "continueShopping",
    //     eventLabel: window.location.hostname,
    //   });
    //   this.$store.dispatch("updateMenuOpened", false);
    // },
  },
};
</script>

<style scoped>
.separator {
  border: 0;
  border-top: 1px solid grey;
}
.larger-font {
  font-size: 2rem;
}
.shoe-image {
  width: 90px;
  height: 90px;
}
</style>

<template>
  <div id="uploadedImages">
    <ul class="list-unstyled">
      <UploadedImage v-for="(image, idx) in images.concat(imagesProcessing)" :key="image.id" :image="image" :idx="idx"></UploadedImage>
    </ul>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {db} from '../firebase';
  import UploadedImage from "./UploadedImage";

  export default {
    name: "UploadedImages",
    components: {UploadedImage},
    computed: {
      ...mapState(['user', 'sizeMapping', 'gender', 'bestMeasurement'])
    },
    data() {
      return {
        images: [],
        imagesProcessing: []
      }
    },
    mounted() {
      this.$root.$on('upload-end', data => {
        this.imagesProcessing.push({is_processing: true, id:data.id, thumbnail:data.downloadUrl})
      });
    },
    firestore() {
      return {
        images: db.collection("users").doc(this.user.uid).collection("images")
      }
    },
    watch: {
      user: {
        handler(user) {
          this.$store.dispatch("updateBestMeasurement", null)
          db.collection('users').doc(this.user.uid).collection('images').get()
            .then(result => {
              if (result.docs.length > 0) {
                let uploads = result.docs.map(upload => {
                  let data = upload.data()
                  data.id = upload.id
                  if (this.bestMeasurement == null || this.bestMeasurement.sum_of_all_errors > data.sum_of_all_errors) {
                    this.$store.dispatch("updateBestMeasurement", data)
                  }
                  return data
                })
                this.$store.dispatch("updateUploads", uploads)
              } else {
                this.$store.dispatch("updateUploads", [])
              }
              this.$ga.event({
                eventCategory: 'Uploads',
                eventAction: 'loadUploads',
                eventValue: result.docs.length,
                eventLabel: window.location.hostname
              })
            })
            .catch(reason => {
              if (this.userProfile === []) {
                this.$store.dispatch("updateUploads", this.uploads)
              }
            })
        }
      },
      images: {
        immediate: true,
        handler(images) {
          let imageIds = images.map(image => image.id)
          this.imagesProcessing = this.imagesProcessing.filter(function(el) {
            return imageIds.includes(el.id)
          })
          this.$store.dispatch("updateUploads", images)
          this.$store.dispatch("updateBestMeasurement", null)
          for (var i=0; i<images.length; i++) {
            if (this.bestMeasurement == null || this.bestMeasurement.sum_of_all_errors > images[i].sum_of_all_errors) {
              this.$store.dispatch("updateBestMeasurement", images[i])
            }
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
import Vue from "vue";
import Router from "vue-router";
import Introduction from "./views/Introduction"
import Login from "./views/Login";
import Recommendation from "./views/Recommendation";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import Error from "./views/Error"

Vue.use(Router);

let router = new Router({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/introduction",
      name: "Introduction",
      component: Introduction
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true
    },
    {
      path: '/stepone',
      name: 'StepOne',
      component: StepOne
    },
    {
      path: '/steptwo',
      name: 'StepTwo',
      component: StepTwo
    },
    {
      path: '/recommendation',
      name: 'Recommendation',
      component: Recommendation
    },
    {
      path: "/error",
      name: "Error",
      component: Error,
      props: true
    }
  ]
});

export default router;
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    savedPhoneNumbers: state.savedPhoneNumbers,
    uploads: state.uploads,
    user: state.user,
    clientId: state.clientId,
    sizeMapping: state.sizeMapping,
    bestMeasurement: state.bestMeasurement,
    gender: state.gender
    //don't save phoneNumbers or sku
  })
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default new Vuex.Store({
  state: {
    clientId: null,
    phoneNumbers: [],
    savedPhoneNumbers: [],
    uploads: [],
    user: null,
    sku: null,
    sizeMapping: {},
    menuOpened: false,
    bestMeasurement: null, //best measurement for user
    gender: null,
    title: null,
    subtitle: null,
    imgsrc: null
  },
  mutations: {
    UPDATE_CLIENT_ID(state, clientId) {
      state.clientId = clientId
    },
    ADD_PHONE_NUMBER(state, payload) {
      if (!state.phoneNumbers.some(number => number.number === payload.number)) {
        state.phoneNumbers.push(payload)
      }
    },
    DELETE_PHONE_NUMBER(state, payload) {
      let filtered = state.phoneNumbers.filter(value => value.number !== payload.number)
      state.phoneNumbers = filtered
    },
    UPDATE_PHONE_NUMBERS(state, phoneNumbers) {
      state.phoneNumbers = phoneNumbers.slice(0) //create a clone. otherwise it references it
    },
    ADD_SAVED_PHONE_NUMBER(state, savedPhoneNumber) {
      if (!state.savedPhoneNumbers.some(number => number.number === savedPhoneNumber.number)) {
        state.savedPhoneNumbers.push(savedPhoneNumber)
      }
    },
    DELETE_SAVED_PHONE_NUMBER(state, savedPhoneNumber) {
      let filtered = state.savedPhoneNumbers.filter(value => value.number !== savedPhoneNumber.number)
      state.savedPhoneNumbers = filtered
    },
    UPDATE_SAVED_PHONE_NUMBERS(state, savedPhoneNumbers) {
      state.savedPhoneNumbers = savedPhoneNumbers.slice(0) //create a clone. otherwise it references it
    },
    UPDATE_UPLOADS(state, uploads) {
      state.uploads = uploads.slice(0)
    },
    UPDATE_USER(state, user) {
      state.user = {
        'uid': user.uid,
        'name': user.displayName,
        'email': user.email,
        'photoUrl': user.photoURL,
        'phoneNumber': user.phoneNumber,
        'providerId': user.providerId,
        'isAnonymous': user.isAnonymous
      }
    },
    UPDATE_SKU(state, sku) {
      state.sku = sku
    },
    UPDATE_SIZE_MAPPING(state, sizeMapping) {
      state.sizeMapping = sizeMapping
    },
    UPDATE_MENU_OPENED(state, isOpened) {
      state.menuOpened = isOpened
    },
    UPDATE_BEST_MEASUREMENT(state, bestMeasurement) {
      state.bestMeasurement = bestMeasurement
    },
    UPDATE_GENDER(state, gender) {
      state.gender = gender
    },
    UPDATE_TITLE(state, title) {
      state.title = title
    },
    UPDATE_SUBTITLE(state, subtitle) {
      state.subtitle = subtitle
    },
    UPDATE_IMGSRC(state, imgsrc) {
      state.imgsrc = imgsrc
    }
  },
  actions: {
    updateClientId(context, clientId) {
      context.commit("UPDATE_CLIENT_ID", clientId);
    },
    addPhoneNumber(context, phoneNumber) {
      context.commit("ADD_PHONE_NUMBER", phoneNumber);
    },
    updatePhoneNumbers(context, phoneNumbers) {
      context.commit("UPDATE_PHONE_NUMBERS", phoneNumbers);
    },
    deletePhoneNumber(context, phoneNumber) {
      context.commit("DELETE_PHONE_NUMBER", phoneNumber);
    },
    addSavedPhoneNumber(context, savedPhoneNumber) {
      context.commit("ADD_SAVED_PHONE_NUMBER", savedPhoneNumber);
    },
    deleteSavedPhoneNumber(context, savedPhoneNumber) {
      context.commit("DELETE_SAVED_PHONE_NUMBER", savedPhoneNumber);
    },
    addUpload(context, upload) {
      context.commit("ADD_UPLOAD", upload);
    },
    deleteUpload(context, upload) {
      context.commit("DELETE_UPLOAD", upload);
    },
    updateSavedPhoneNumbers(context, savedPhoneNumbers) {
      context.commit("UPDATE_SAVED_PHONE_NUMBERS", savedPhoneNumbers);
    },
    updateUploads(context, uploads) {
      context.commit("UPDATE_UPLOADS", uploads)
    },
    updateUser(context, user) {
      context.commit("UPDATE_USER", user)
    },
    updateSku(context, sku) {
      context.commit("UPDATE_SKU", sku)
    },
    updateSizeMapping(context, sizeMapping) {
      context.commit("UPDATE_SIZE_MAPPING", sizeMapping)
    },
    updateMenuOpened(context, isOpened) {
      context.commit("UPDATE_MENU_OPENED", isOpened)
    },
    updateBestMeasurement(context, bestMeasurement) {
      context.commit("UPDATE_BEST_MEASUREMENT", bestMeasurement)
    },
    updateGender(context, gender) {
      context.commit("UPDATE_GENDER", gender)
    },
    updateTitle(context, title) {
      context.commit("UPDATE_TITLE", title)
    },
    updateSubtitle(context, subtitle) {
      context.commit("UPDATE_SUBTITLE", subtitle)
    },
    updateImgsrc(context, imgsrc) {
      context.commit("UPDATE_IMGSRC", imgsrc)
    }
  },
  getters: {
    getClientId(state) {
      return state.clientId
    },
    getPhoneNumbers(state) {
      return state.phoneNumbers;
    },
    getSavedPhoneNumbers(state) {
      return state.savedPhoneNumbers;
    },
    getUploads(state) {
      return state.uploads;
    },
    getUser(state) {
      return state.user;
    },
    getSku(state) {
      return state.sku;
    },
    getSizeMapping(state) {
      return state.sizeMapping
    },
    getMenuOpened(state) {
      return state.menuOpened
    },
    getBestMeasurement(state) {
      return state.bestMeasurement
    },
    getGender(state) {
      return state.gender
    },
    getTitle(state) {
      return state.title
    },
    getSubtitle(state) {
      return state.subtitle
    },
    getImgsrc(state) {
      return state.imgsrc
    }
  },
  plugins: [vuexLocalStorage.plugin]
});

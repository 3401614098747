<template>
  <div>
    <b-card-body>
      <b-card-text class="text-center">
        <router-link to="/sizefinder">Something went wrong. Please try again.</router-link>
      </b-card-text>
    </b-card-body>
    <b-card-body body-text-variant="danger">
      <b-card-text class="-text-center">
        {{message}}
      </b-card-text>
    </b-card-body>
  </div>
</template>

<script>
  export default {
    name: "Error",
    props: {
      code: String,
      message: String
    }
  }
</script>

<style scoped>

</style>
<template>
  <div>
    <b-button :id="`popover-${image.id}-bottomleft`"
              class="font-weight-bolder larger-font"
              size="sm" variant="light">
      <b-spinner v-if="image.is_processing" variant="secondary" small label="Processing..."></b-spinner>
      {{ resolveSize() }}
    </b-button>
    <b-popover
        :target="`popover-${image.id}-bottomleft`"
        placement="bottomright"
        triggers="hover focus"
        :content="`${resolvePopoverContent()}`">
    </b-popover>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "Size",
    props: {
      image: Object,
      isGood: Boolean
    },
    computed: {
      calculateSize() {
        if (!this.isGood) {
          return null
        }
        let mapping = this.sizeMapping.mapping;
        if (this.gender) {
          mapping = mapping[this.gender]
        }
        let result = mapping.find((o) => {
          if (this.image.foot_length >= o.from && this.image.foot_length < o.to) {
            return true;
          }
        })
        if (result) {
          return result.size
        } else {
          return -1
        }
      },
      ...mapState(['user', 'sizeMapping', 'gender']),
    },
    methods: {
      resolveSize() {
        if (this.image.is_processing) {
          return ""
        }
        if (this.calculateSize == null) {
          return "--"
        } else if (this.calculateSize === -1) {
          return "N/A"
        } else {
          return this.calculateSize
        }
      },
      resolvePopoverContent() {
        let size = this.calculateSize;
        if (size == null) {
          return "Sorry. We couldn't determine the size based on the photo provided. Please submit another one."
        }
        let foot_width = this.image.foot_width ? "width of " + this.image.foot_width.toFixed(2) + " cm and " : "";
        let paper_size = this.image.paper_size ? this.image.paper_size : "Letter";
        if (size > -1) {
          return "Recommended size based on measured foot length of " + this.image.foot_length.toFixed(2) + " cm and " + foot_width + paper_size + " paper size"
        } else {
          return "This retailer doesn't carry the shoe in your size. Size based on measured foot length of " + this.image.foot_length.toFixed(2) + " cm and " + paper_size + " paper size"
        }
      }
    }
  }
</script>

<style scoped>
  .larger-font {
    font-size: 1.00rem !important;
  }
</style>
<template>
  <b-card no-body flush>
    <ImageByUpload v-bind:slim-mode="true"></ImageByUpload>
    <UploadedImages></UploadedImages>
    <b-card-body class="py-0">
      <div class="separator"></div>
    </b-card-body>
    <!--
    <ImageByPhone v-bind:slim-mode="true"></ImageByPhone>
    -->
    <SavedPhoneNumbers></SavedPhoneNumbers>
  </b-card>
</template>

<script>
  import UploadedImages from "./UploadedImages";
  import ImageByUpload from "./ImageByUpload";
  // import ImageByPhone from "./ImageByPhone";
  import SavedPhoneNumbers from "./SavedPhoneNumbers";

  export default {
    name: "ImageManagerSlim",
    components: {SavedPhoneNumbers, ImageByUpload, UploadedImages}
  }
</script>

<style scoped>
  .separator {
    border: 0;
    border-top: 1px solid lightgrey;
  }
</style>

<template>
  <b-card no-body flush class="border-0">
    <ImageByUpload v-bind:slim-mode="false"></ImageByUpload>
    <UploadedImages v-bind:slim-mode="false"></UploadedImages>
    <!--
    <b-card-body class="py-0">
      <h4>Or</h4>
    </b-card-body>
    <ImageByPhone></ImageByPhone>
    -->
    <SavedPhoneNumbers></SavedPhoneNumbers>
  </b-card>
</template>

<script>
  import UploadedImages from "./UploadedImages";
  import ImageByUpload from "./ImageByUpload";
  // import ImageByPhone from "./ImageByPhone";
  import SavedPhoneNumbers from "./SavedPhoneNumbers";

  export default {
    name: "ImageManager",
    components: {SavedPhoneNumbers, ImageByUpload, UploadedImages}
  }
</script>

<style scoped>
  .separator {
    border: 0;
    border-top: 1px solid lightgrey;
  }
  h4 {
    overflow: hidden;
    text-align: center;
  }

  h4:before,
  h4:after {
    background-color: lightgrey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  h4:before {
    right: 0.5em;
    margin-left: -50%;
  }

  h4:after {
    left: 0.5em;
    margin-right: -50%;
  }
</style>

<template>
  <b-card
      title="Upload the photo"
      align="center"
  >
    <b-card-text class="text-left">
      <ImageManager></ImageManager>
    </b-card-text>
    <b-card-text>
      <b-button class="mr-1" variant="dark" v-on:click="$router.replace({name:'StepOne'})">&lt;</b-button>
    </b-card-text>
  </b-card>
</template>

<script>
  import ImageManager from "./ImageManager";
  export default {
    name: "StepTwo",
    components: {ImageManager},
    computed: {
      imageCount() {
        return this.$store.state.uploads.length + this.$store.state.savedPhoneNumbers.length
      }
    },
    mounted() {
      if (this.imageCount > 0) {
        this.$router.replace({name:'Recommendation'})
      }
    },
    watch: {
      imageCount: {
        handler(imageCount) {
          if (imageCount > 0) {
            this.$router.replace({name:'Recommendation'})
          }
        }
      }
    }
  }
</script>

<style scoped>
  .separator {
    border: 0;
    border-top: 1px solid lightgrey;
  }
</style>
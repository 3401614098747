<template>
  <b-modal :ref="`modal-${image.id}`" :id="`modal-${image.id}`" title="How does it look?"
           body-class="h-60 d-inline-block p-0">
    <b-img class="foot-image w-100" :src="image.url" alt="foot"></b-img>
    <ul class="mb-0">
      <li>Are the red lines outlining the paper correctly?</li>
      <li>Is the red dot at the tip of the foot?</li>
    </ul>
    <template v-slot:modal-footer>
      <b-button size="sm" variant="danger" @click="mark(false)">
        X
      </b-button>
      <b-button size="sm" variant="success" @click="mark(true)">
        &#10003;
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import {mapState} from 'vuex'
  import {db} from '../firebase';

  export default {
    name: "HowDoesItLook",
    props: {
      image: Object,
      phoneNumber: Object
    },
    computed: {
      ...mapState(['user'])
    },
    methods: {
      mark(good) {
        let self = this
        this.$ga.event({
          eventCategory: 'Image',
          eventAction: 'looksGood',
          eventLabel: window.location.hostname
        })
        let images = this.phoneNumber ? db.collection("users").doc(this.user.uid).collection('phone_numbers').doc(this.phoneNumber.number).collection("images") : db.collection("users").doc(this.user.uid).collection("images")
        let imageRef = images.doc(this.image.id)
        imageRef.get().then(docSnap => {
          if (docSnap.exists) {
            imageRef
              .update({isGood: good})
              .then(value => {
                self.$refs['modal-' + self.image.id].hide()
              }) //maybe put a spinner here
              .catch(reason => console.log(reason))
          } else {
            imageRef
              .set({isGood: good})
              .then(value => {
                self.$refs['modal-' + self.image.id].hide()
              }) //maybe put a spinner here
              .catch(reason => console.log(reason))
          }
        })
      }
    }
  }
</script>

<style scoped>
  .foot-image {
    object-fit: contain;
    /*max-width: 500px;*/
    max-height: 65vh;
    /*height: 500px;*/
  }
</style>
<template>
  <b-card-body align="center" class="py-1">
    <image-uploader
      :preview="false"
      :className="['fileinput']"
      :maxWidth="1200"
      :autoRotate="true"
      outputFormat="blob"
      @input="setImage"
    >
      <label
        for="fileInput"
        slot="upload-label"
        class="btn btn-light cursor-pointer mb-0"
      >
        <span class="upload-caption">
          Upload image
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="15pt"
            height="15pt"
            viewBox="0 0 15 15"
            version="1.1"
          >
            <g id="surface1">
              <path
                style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
                d="M 4.453125 8.90625 C 4.453125 10.589844 5.816406 11.953125 7.5 11.953125 C 9.183594 11.953125 10.546875 10.589844 10.546875 8.90625 C 10.546875 7.222656 9.183594 5.859375 7.5 5.859375 C 5.816406 5.859375 4.453125 7.222656 4.453125 8.90625 Z M 14.0625 3.75 L 10.78125 3.75 C 10.546875 2.8125 10.3125 1.875 9.375 1.875 L 5.625 1.875 C 4.6875 1.875 4.453125 2.8125 4.21875 3.75 L 0.9375 3.75 C 0.421875 3.75 0 4.171875 0 4.6875 L 0 13.125 C 0 13.640625 0.421875 14.0625 0.9375 14.0625 L 14.0625 14.0625 C 14.578125 14.0625 15 13.640625 15 13.125 L 15 4.6875 C 15 4.171875 14.578125 3.75 14.0625 3.75 Z M 7.5 13.066406 C 5.203125 13.066406 3.339844 11.203125 3.339844 8.90625 C 3.339844 6.609375 5.203125 4.746094 7.5 4.746094 C 9.796875 4.746094 11.660156 6.609375 11.660156 8.90625 C 11.660156 11.203125 9.796875 13.066406 7.5 13.066406 Z M 14.0625 6.5625 L 12.1875 6.5625 L 12.1875 5.625 L 14.0625 5.625 Z M 14.0625 6.5625 "
              />
            </g></svg
        ></span>
      </label>
    </image-uploader>
    <b-progress
      v-if="uploading"
      :value="progressUpload"
      show-progress
      animated
    ></b-progress>
  </b-card-body>
</template>

<script>
import { mapState } from "vuex";
import { storage } from "../firebase";

export default {
  name: "ImageByUpload",
  data() {
    return {
      image: null,
      progressUpload: 0,
      fileName: "",
      uploadTask: "",
      uploading: false,
      downloadURL: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  props: {
    slimMode: Boolean,
  },
  methods: {
    setImage: function(blob) {
      this.$ga.event({
        eventCategory: "Image",
        eventAction: "uploadImage",
        eventLabel: window.location.hostname,
      });
      var metadata = {
        customMetadata: {
          site:
            window.location.hostname === "demo.footprynt.io"
              ? window.location.href
              : window.location.hostname,
        },
      };
      this.image = blob;
      this.fileName = Date.now();
      this.uploading = true;
      this.uploadTask = storage
        .ref("users/" + this.user.uid + "/images/" + this.fileName)
        .put(blob, metadata);
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.$root.$emit("upload-end", {
              downloadUrl: downloadURL,
              id: this.fileName,
            });
            this.uploading = false;
            this.progressUpload = 0;
            this.fileName = "";
            this.image = null;
            this.downloadURL = downloadURL;
            this.$emit("downloadURL", downloadURL);
          });
        }
      );
    },
  },
};
</script>

<style>
svg {
  vertical-align: text-top !important;
}
#fileInput {
  display: none;
}

.upload-caption {
  font-size: 17px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>

import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import store from './store'

const firebaseApp = Firebase.initializeApp({
  apiKey: "AIzaSyBfd7_S4-vLURpoxrKCE3MARBrPFKxREag",
  authDomain: "widget.footprynt.io",
  databaseURL: "https://footprynt-f71fc.firebaseio.com",
  projectId: "footprynt-f71fc",
  storageBucket: "footprynt-original",
  messagingSenderId: "1025434921389",
  appId: "1:1025434921389:web:514819990abb11ed"

});

firebaseApp.auth().onAuthStateChanged(user => {
  if (user != null) {
    store.dispatch("updateUser", user)
  } else {
    firebaseApp.auth().signInAnonymously().then(function (user) {
      // store.dispatch("updateUser", null)
      //also clear out savedPhoneNumbers
      store.dispatch("updatePhoneNumbers", [])
      store.dispatch("updateSavedPhoneNumbers", [])
      store.dispatch("updateUploads", [])
      store.dispatch("updateBestMeasurement", null)
    });
  }
})

firebaseApp.firestore().enablePersistence({
  synchronizeTabs: true,
  cacheSizeBytes: firebaseApp.firestore.CACHE_SIZE_UNLIMITED
})

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const firebase = Firebase;
<template>
  <b-list-group flush>
    <b-list-group-item v-for="(phoneNumber, idx) in savedPhoneNumbers" :key="idx" class="p-0 border-0">
      <PhoneNumberImages :phoneNumber="phoneNumber" :idx="idx"></PhoneNumberImages>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
  import {mapState} from 'vuex'
  import {db} from '../firebase';
  import PhoneNumberImages from "@/components/PhoneNumberImages";

  export default {
    name: "SavedPhoneNumbers",
    components: {PhoneNumberImages},
    computed: {
      ...mapState(['user', 'phoneNumbers', 'savedPhoneNumbers'])
    },
    watch: {
      user: {
        immediate: true,
        handler(user) {
          db.collection('users').doc(this.user.uid).collection('phone_numbers').get()
            .then(result => {
              if (result.docs.length > 0) {
                let savedNumbers = result.docs.map(number => {
                  let pn = this.resolvePhoneNumber(number.id)
                  let data = number.data()
                  if (data.name)
                    pn.name = data.name
                  return pn
                })
                this.$store.dispatch("updateSavedPhoneNumbers", savedNumbers)
              } else {
                this.$store.dispatch("updateSavedPhoneNumbers", [])
              }
              this.$ga.event({
                eventCategory: 'Widget',
                eventAction: 'loadSavedPhoneNumbers',
                eventValue: result.docs.length,
                eventLabel: window.location.hostname
              })
            })
            .catch(reason => {
              if (this.userProfile === []) {
                this.$store.dispatch("updateSavedPhoneNumbers", this.savedPhoneNumbers)
              }
            })
        }
      }
    }
  }
</script>

<style scoped>

</style>
<template>
  <span v-if="isClientActive">
    <div class="footprynt-text" @click="openMenu">
      {{ derivedText }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="15pt"
        height="15pt"
        viewBox="0 0 15 15"
        version="1.1"
      >
        <g id="surface1">
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 8.707031 2.816406 C 7.277344 2.304688 5.484375 2.660156 5.003906 3.710938 C 4.558594 4.695312 4.746094 5.332031 5.339844 6.132812 C 5.914062 6.902344 6.828125 7.40625 6.867188 8.757812 C 6.910156 10.210938 6.484375 10.949219 6.179688 12.0625 C 5.941406 12.941406 5.867188 14.488281 7.320312 14.902344 C 8.386719 15.207031 9.121094 14.777344 9.5625 13.921875 C 10.175781 12.742188 10.058594 11.195312 10.183594 9.078125 C 10.277344 7.460938 10.875 6.695312 10.875 5.246094 C 10.875 4.136719 10.246094 3.363281 8.707031 2.816406 Z M 8.707031 2.816406 "
          />
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 5.027344 0.0195312 C 4.03125 -0.148438 3.695312 0.796875 3.804688 1.5 C 3.898438 2.097656 4.351562 2.757812 5.023438 2.722656 C 5.699219 2.6875 5.960938 2.066406 5.976562 1.515625 C 5.992188 0.859375 5.683594 0.128906 5.027344 0.0195312 Z M 5.027344 0.0195312 "
          />
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 7.617188 1.144531 C 7.683594 1.601562 7.453125 2.015625 7.105469 2.074219 C 6.753906 2.128906 6.417969 1.804688 6.351562 1.351562 C 6.285156 0.894531 6.511719 0.476562 6.863281 0.421875 C 7.210938 0.367188 7.550781 0.6875 7.617188 1.144531 Z M 7.617188 1.144531 "
          />
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 9.050781 1.453125 C 9.074219 1.863281 8.839844 2.214844 8.523438 2.238281 C 8.207031 2.257812 7.929688 1.945312 7.90625 1.535156 C 7.878906 1.121094 8.117188 0.773438 8.433594 0.75 C 8.75 0.726562 9.027344 1.042969 9.050781 1.453125 Z M 9.050781 1.453125 "
          />
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 10.246094 2.070312 C 10.246094 2.429688 10.03125 2.71875 9.765625 2.726562 C 9.496094 2.730469 9.273438 2.445312 9.273438 2.085938 C 9.269531 1.730469 9.484375 1.4375 9.753906 1.433594 C 10.019531 1.429688 10.242188 1.714844 10.246094 2.070312 Z M 10.246094 2.070312 "
          />
          <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 11.214844 2.996094 C 11.207031 3.332031 11.003906 3.597656 10.761719 3.59375 C 10.515625 3.585938 10.328125 3.3125 10.335938 2.976562 C 10.34375 2.644531 10.546875 2.378906 10.789062 2.382812 C 11.03125 2.386719 11.222656 2.664062 11.214844 2.996094 Z M 11.214844 2.996094 "
          />
        </g>
      </svg>
    </div>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FootpryntText",
  data() {
    return {
      derivedText: this.text,
      isClientActive: false,
    };
  },
  props: {
    text: String,
  },
  computed: {
    ...mapState(["bestMeasurement", "sizeMapping", "gender"]),
  },
  mounted() {
    this.isClientActive = process.env.VUE_APP_ACTIVE_CLIENTS.split(
      ","
    ).includes(window.location.hostname);
    this.updateText();
  },
  methods: {
    openMenu() {
      this.$ga.event({
        eventCategory: "Widget",
        eventAction: "widgetOpened",
        eventLabel: window.location.hostname,
      });
      this.$store.dispatch("updateMenuOpened", true);
    },
    updateText() {
      if (this.bestMeasurement != null) {
        let mapping = this.sizeMapping.mapping;
        if (this.gender) {
          mapping = mapping[this.gender];
        }
        let result = mapping.find((o) => {
          if (
            this.bestMeasurement.foot_length >= o.from &&
            this.bestMeasurement.foot_length < o.to
          ) {
            return true;
          }
        });
        if (result) {
          this.derivedText =
            "Your Footprynt size is " +
            (result.size > -1 ? result.size : "N/A");
        }
      } else {
        this.derivedText = this.text;
      }
    },
  },
  watch: {
    bestMeasurement: function(val) {
      this.updateText();
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Krub&display=swap");

footprynt-text {
  font-family: "Krub", sans-serif;
}

.footprynt-text {
  position: fixed;
  height: 50px !important;
  right: 20px !important;
  bottom: 20px !important;
  /* cursor: pointer; */
}
</style>
